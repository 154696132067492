"use client"

import { createContext, useRef, useState, useEffect } from "react";
import api from "@/utils/api";
import customAlert from "@/utils/customAlert";

interface SlotContextType {
    allSlots: any;
}

export const SlotContext = createContext<SlotContextType | null>(null);

export function SlotProvider({ children }: any) {
    const [allSlots, setAllSlots] = useState<any>()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api("/api/game/games/all/");
                if (!response.ok) throw new Error("ERROR");
                const result = await response.json();
                setAllSlots(result);
            }
            catch (error) {
                if (process.env.NODE_ENV === 'production') return;
                customAlert("Failed to load slot meta data", "warning");
            }
        }

        fetchData();
    }, []);

    return (
        <SlotContext.Provider value={{ allSlots }}>
            {children}
        </SlotContext.Provider>
    )
}
