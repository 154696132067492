"use client"

import { request } from "http";
import { createContext, useRef, useState, useEffect } from "react";

interface RouterContextType {
    requestedAt?: Date
    handleRequest?: any
    getProps: () => any,
}

const RouteContext = createContext<RouterContextType>({ getProps: () => { } })

function RouteProvider({ children }: any) {
    const [requestedAt, setRequestedAt] = useState<Date>()
    const propsRef = useRef()

    function handleRequest(props: any) {
        setRequestedAt(new Date())
        propsRef.current = props
    }

    function getProps() {
        return propsRef.current
    }

    return (
        <RouteContext.Provider value={{ requestedAt, handleRequest, getProps }}>
            {children}
        </RouteContext.Provider>
    )
}

export { RouteContext, RouteProvider }