"use client";

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'
import { useRouter } from 'next/navigation'

import { AuthContext } from './authContext'
import SigninModal from '@/components/Primitive/Modal/SigninModal'
import Mobile_SigninModal from '@/components/Primitive/Modal/Mobile_SigninModal'

export const SinginModalContext = createContext<{
    showModal: boolean,
    setSigninModalShow: (show: boolean) => void,
    UserAuthCheck: (callback: () => void) => void
}>({
    showModal: false,
    setSigninModalShow: () => { },
    UserAuthCheck: () => { },
})

export function SigninModalProvider({ children }: { children: any }) {
    const router = useRouter()
    const { user, loading } = useContext(AuthContext)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [isMobile, setIsMobile] = React.useState<boolean>(false);

    const resize = useCallback(() => {
        if (typeof window !== 'undefined') {
            const shouldBeMobile = window.innerWidth < 768;
            if (shouldBeMobile !== isMobile) setIsMobile(shouldBeMobile);
        }
    }, [isMobile]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', resize);
            resize();

            return () => {
                window.removeEventListener('resize', resize);
            };
        }
    }, [resize]);

    function SetShowModal(isShow: boolean) {
        setShowModal(isShow)
    }

    function UserAuthCheck(callback: () => void) {
        if (user.is_authenticated) {
            callback()
        } else {
            setShowModal(true)
        }
    }

    useEffect(() => {
        if (!loading && user?.is_authenticated == false) {
            router.push('/')
        }
    }, [user, loading, router])


    return (
        <SinginModalContext.Provider value={{ UserAuthCheck, showModal, setSigninModalShow: SetShowModal }}>
            {children}
            {
                isMobile
                    ? <Mobile_SigninModal show={showModal} setShow={setShowModal} />
                    : <SigninModal show={showModal} setShow={setShowModal} />
            }
        </SinginModalContext.Provider>
    )
}
