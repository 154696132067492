'use client';

import 'bootstrap/dist/css/bootstrap.min.css';
import "@/siteConfig/static/css/main.min.css";

import React, { FC, useCallback, useEffect, useState, useContext } from "react";
import { siteMeta } from "@/siteConfig/settings/siteSetting";
import LanguageProvider from "@/contexts/languageContext";
import { SiteProvider } from "@/contexts/siteContext";
import { RouteProvider } from "@/contexts/routeContext";
import { AuthProvider } from "@/contexts/authContext";
import { SigninModalProvider } from "@/contexts/SigninModalContext";
import { ToggleMenuProvider } from "@/contexts/toggleMenuContext";
import { SlotProvider } from '@/contexts/slotContext';

import { RenderLayout } from "./RenderLayout";
import { defaultLayout, popupNotificationLayout } from "@/siteConfig/settings/layoutSetting";
import { mobileDefaultLayout, mobilePopupNotificationLayout } from "@/siteConfig/settings/mobileLayoutSetting";
import { RouteContext } from '@/contexts/routeContext';

export default function RootLayout({
    children,
}: Readonly<{
    children: React.ReactNode;
}>) {
    const [isMobile, setIsMobile] = React.useState<boolean>(false);
    const [background, setBackground] = useState("rgba(0, 0, 0, 0)");
    const { requestedAt } = useContext(RouteContext);

    const resize = useCallback(() => {
        if (typeof window !== 'undefined') {
            const shouldBeMobile = window.innerWidth < 768;
            if (shouldBeMobile !== isMobile) setIsMobile(shouldBeMobile);
        }
    }, [isMobile]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', resize);
            resize();

            return () => {
                window.removeEventListener('resize', resize);
            };
        }
    }, [resize]);

    useEffect(() => {
        const handleScroll = () => {
            if (!isMobile) return;
            if (window.scrollY > 20) {
                setBackground("#2b2c30");
            } else {
                setBackground("rgba(0, 0, 0, 0)"); // 투명
            }
        };

        window.addEventListener("scroll", handleScroll);

        // 클린업 함수로 스크롤 이벤트 제거
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isMobile]);

    return (
        <html lang="ko">
            <head>
                <title>{siteMeta.siteName}</title>
            </head>
            <body>
                <RouteProvider>
                    <ToggleMenuProvider>
                        <LanguageProvider>
                            <SiteProvider>
                                <AuthProvider>
                                    <SigninModalProvider>
                                        <SlotProvider>
                                            <RenderLayout layout={
                                                isMobile
                                                    ? mobilePopupNotificationLayout.popupNotification
                                                    : popupNotificationLayout.popupNotification
                                            } />
                                            <div className="root-container">
                                                <header>
                                                    <div className={`${isMobile ? "root-header-mobile" : "root-header"}`}
                                                        style={{ backgroundColor: background, transition: "background-color 0.3s ease" }}
                                                    >
                                                        <RenderLayout layout={
                                                            isMobile
                                                                ? mobileDefaultLayout.header
                                                                : defaultLayout.header
                                                        } />
                                                    </div>
                                                </header>
                                                <RenderLayout layout={
                                                    isMobile
                                                        ? mobileDefaultLayout.nav
                                                        : defaultLayout.nav
                                                } />
                                                <main>
                                                    {children}
                                                </main>
                                                <footer>
                                                    <RenderLayout layout={
                                                        isMobile
                                                            ? mobileDefaultLayout.footer
                                                            : defaultLayout.footer
                                                    } />
                                                </footer>
                                            </div>
                                        </SlotProvider>
                                    </SigninModalProvider>
                                </AuthProvider>
                            </SiteProvider>
                        </LanguageProvider>
                    </ToggleMenuProvider>
                </RouteProvider>
            </body>
        </html>
    );
}
