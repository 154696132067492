import { supportedLanguages } from "@/contexts/languageContext";

type LayoutType = string | { [key: string]: string };

export const mobileDefaultLayout: { [key: string]: { [key: string]: LayoutType } } = {
    header: {
        Logo: "Mobile_Type1",
        // SupportedLanguage: "Mobile_Type1",
        AuthUserHeader: "Mobile_Type1",
        AnonymousUserHeader: "Mobile_Type1",
        Navigation: "Mobile_Type1",
    },
    nav: {},
    main: {},
    footer: {
        MobileFooter: "Mobile_Type1",
    },
};

//팝업 공지
export const mobilePopupNotificationLayout: { [key: string]: { [key: string]: LayoutType } } = {
    popupNotification: {
        PopupNotification: "Mobile_Type1"
    }
}

//랜딩 페이지
export function setMobileLandingPage() {
    const layout = {
        "landing": {
            Banner: "Mobile_Type1",
            LineNotice: "Mobile_Type1",
            MobileShortcut: "Mobile_Type1",
            MobileLandingGames: "Mobile_Type1",
        }
    }

    return layout;
}

//카지노 페이지
export function setMobileCasinoPage() {
    const layout = {
        "casino": {
            Casino: "Mobile_Type1",
        }
    }
    return layout;
}

//슬롯 페이지
export function setMobileSlotPage() {
    const layout = {
        "slot": {
            Slot: "Mobile_Type1",
        }
    }
    return layout;
}

//스포츠 페이지
export function setMobileSportsPage() {
    const layout = {
        "sports": {
            SportsFilterBox: "Mobile_Type1",
            "sportsMain": {
                // SportsHeader: "Mobile_Type1",
                SportsSearchForm: "Mobile_Type1",
                SportsPrematch: "Mobile_Type1",
            },
            SportsBetSlip: "Mobile_Type1",
        }
    }
    return layout;
}


//스포츠 배팅내역 페이지
export function setMobileSportsBetHistoryPage() {
    const layout = {
        "sportsBettingHistory": {
            SportsBetHistory: "Mobile_Type1",
        }
    }
    return layout;
}

//홀덤 페이지
export function setMobileHoldemPage() {
    const layout = {
        "holdem": {
            Holdem: "Mobile_Type1",
        }
    }
    return layout;
}

//충전 페이지
export function setMobileDepositPage() {
    const layout = {
        "deposit": {
            Deposit: "Mobile_Type1",
        }
    }
    return layout;
}

//충전 내역 페이지
export function setMobileDepositHistoryPage() {
    const layout = {
        "depositHistory": {
            DepositHistory: "Mobile_Type1",
        }
    }
    return layout;
}

//환전 페이지
export function setMobileWithdrawalPage() {
    const layout = {
        "withdrawal": {
            Withdrawal: "Mobile_Type1",
        }
    }
    return layout;
}

//환전 내역 페이지
export function setMobileWithdrawalHistoryPage() {
    const layout = {
        "withdrawalHistory": {
            WithdrawalHistory: "Mobile_Type1",
        }
    }
    return layout;
}

//이벤트 페이지
export function setMobileEventePage() {
    const layout = {
        "event": {
            Event: "Mobile_Type1",
        }
    }
    return layout;
}

//공지사항 페이지
export function setMobileNoticePage() {
    const layout = {
        "notice": {
            Notice: "Mobile_Type1",
        }
    }
    return layout;
}

//비밀번호 변경 페이지
export function setMobilePasswordChangePage() {
    const layout = {
        "passwordChange": {
            PasswordChange: "Mobile_Type1",
        }
    }
    return layout;
}

//쪽지 페이지
export function setMobileMessagePage() {
    const layout = {
        "message": {
            Message: "Mobile_Type1",
        }
    }
    return layout;
}

//고객센터 페이지
export function setMobileCustomerCenterPage() {
    const layout = {
        "customerCenter": {
            CustomerCenter: "Mobile_Type1",
        }
    }
    return layout;
}

//미니게임
export function setMobileMinigamePage() {
    const layout = {
        "minigame": {
            Minigame: "Mobile_Type1",
        }
    }
    return layout;
}

//자산 페이지
export function setMobileAssetPage() {
    const layout = {
        "asset": {
            Asset: "Mobile_Type1",
        }
    }
    return layout;
}