"use client"
import dynamic from 'next/dynamic';
import React, { FC, useEffect, useCallback } from "react";
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';
import { request } from 'http';

export type LayoutItem = string | { [key: string]: LayoutItem };

const loadComponent = (name: string, type: string) => {
    return dynamic(() =>
        import(`@/components/${name}/${type}`)
            .then((mod) => mod.default)
            .catch(() => {
                const FallbackComponent = () => <div>Component not found</div>;
                FallbackComponent.displayName = 'FallbackComponent';
                return FallbackComponent;
            }),
        { ssr: false }
    );
};

const LazyComponent: FC<{ name: string; type: string }> = React.memo(({ name, type }) => {
    const Component = loadComponent(name, type);
    return <Component />;
});

LazyComponent.displayName = 'LazyComponent';

const RenderLayoutComponent: React.FC<{ layout: { [key: string]: LayoutItem }, parentKey?: string }> = ({ layout, parentKey }) => {
    return (
        <I18nextProvider i18n={i18n}>
            {Object.keys(layout).map((key) => {
                const item = layout[key];
                const keyName = parentKey ? `${parentKey}-${key}` : key;
                if (typeof item === 'string') {
                    return <LazyComponent key={keyName} name={key} type={item} />;
                }

                if (typeof item === 'object') {
                    return (
                        <div className={key} key={keyName}>
                            <RenderLayoutComponent layout={item as { [key: string]: LayoutItem }} parentKey={key} />
                        </div>
                    );
                }

                return null;
            })}
        </I18nextProvider>
    );
};

export const RenderLayout: FC<{ layout: { [key: string]: LayoutItem }, parentKey?: string }> = ({ layout, parentKey }) => {
    return (
        <RenderLayoutComponent layout={layout} parentKey={parentKey} />
    );
};
